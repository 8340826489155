import React from 'react';
import SEO from '../components/seo';
import Header from '../components/header';

const AGB = () => (
	<>
		<Header />

		<div style={{ maxWidth: '1064px', padding: '32px', paddingTop: '112px', margin: '0 auto' }}>
			<SEO title="AGB" />

			<h1>Allgemeine Geschäftsbedingungen der THNG GmbH</h1>

			<div style={{ maxWidth: '640px' }}>
				<h5>1 Geltungsbereich</h5>
				<ol>
					<li>
						Diese Allgemeinen Geschäftsbedingungen gelten ausschließlich gegenüber Unternehmen, juristischen
						Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen im Sinne von § 310
						Absatz 1 BGB. Entgegenstehende oder von den Geschäftsbedingungen von THNG abweichende
						Bedingungen des Kunden erkennt THNG nur an, wenn THNG ausdrücklich schriftlich der Geltung
						zugestimmt hat.
					</li>
					<li>
						Diese Allgemeinen Geschäftsbedingungen gelten auch für alle zukünftigen Geschäfte mit dem
						Kunden, soweit es sich um Rechtsgeschäfte verwandter Art handelt.
					</li>
				</ol>

				<h5>2 Angebot und Vertragsschluss</h5>
				<ol>
					<li>
						Im Falle von Entwicklungs- bzw. Beratungsverträgen erstellt THNG auf der Basis der gemeinsam mit
						dem Kunden erarbeiteten Leistungsbeschreibung ein Angebot. Der Kunde ist verpflichtet,
						detailliert seine Anforderungen zu benennen und die Leistungsbeschreibung auf Vollständigkeit zu
						überprüfen. An dieses Angebot hält sich THNG für einen Zeitraum von vier Wochen gebunden.
					</li>
					<li>
						Jede Bestellung von Lieferungen bzw. Beauftragung mit einer sonstigen Leistung durch den Kunden
						gilt als verbindliches Vertragsangebot, sofern sich aus der Bestellung bzw. Beauftragung oder
						den sonstigen Vereinbarungen nichts anderes ergibt. THNG ist berechtigt, dieses Vertragsangebot
						innerhalb von zwei Wochen nach Zugang anzunehmen. Die Annahme kann entweder schriftlich oder
						durch Lieferung bzw. Erbringung der sonstigen Leistungen an den Kunden erklärt werden.
					</li>
				</ol>

				<h5>3 Termine und Fristen</h5>
				<ol>
					<li>
						Termine und Fristen sind verbindlich, wenn sie von THNG und dem Kunden im Einzelfall schriftlich
						als verbindlich vereinbart worden sind. Die Leistungsfrist beginnt, soweit im Einzelfall nichts
						anderes vereinbart ist, mit Vertragsschluss bzw. Absendung der Auftragsbestätigung. Die
						Vereinbarung eines festen Leistungstermins steht unter dem Vorbehalt, dass THNG die notwendigen
						Leistungen der Vorlieferanten rechtzeitig und vertragsgemäß erhält.
					</li>
					<li>
						Die Einhaltung von Terminen und Fristen setzt voraus, dass der Kunde sämtliche für die
						Lieferungen erforderlichen Informationen rechtzeitig zur Verfügung stellt, insbesondere die ihm
						obliegenden Mitwirkungshandlungen erbringt. Sind diese Voraussetzungen nicht erfüllt, so
						verlängern sich die Fristen bzw. Termine angemessen.
					</li>
					<li>
						Ist die Nichteinhaltung von Terminen und Fristen auf höhere Gewalt, z.B. Krieg, Aufruhr, oder
						auf ähnliche Ereignisse, wie Streik, Aussperrung zurückzuführen, verlängern sich die Termine und
						Fristen angemessen. Gleiches gilt für den Fall, dass THNG die notwendigen Leistungen der
						jeweiligen Vorlieferungen nicht rechtzeitig und vertragsgemäß erhält
					</li>
				</ol>

				<h5>4 Änderungen</h5>
				<ol>
					<li>
						Der Kunde kann jederzeit schriftlich eine Änderung der Anforderungen verlangen. THNG wird dieses
						Verlangen überprüfen und dem Kunden mitteilen, ob der Änderungsvorschlag im Rahmen der
						vereinbarten Leistungen durchführbar ist. Ist dies nicht der Fall wird THNG dem Kunden innerhalb
						von zwei Wochen ein schriftliches Angebot zur Durchführung der Änderungen (Änderungsangebot)
						unterbreiten. Das Änderungsangebot enthält insbesondere die Leistungsbeschreibung und deren
						Auswirkungen auf den Leistungszeitraum, die vereinbarten Termine und die Vergütung. Das Angebot
						berücksichtigt auch mögliche Ersparnisse durch Minderaufwendungen.
					</li>
					<li>
						Der Kunde wird dieses Änderungsangebot innerhalb der im Angebot bestimmten Annahmefrist
						(Bindefrist) entweder annehmen oder ablehnen.
					</li>
					<li>
						Bis zur Annahme des Änderungsangebotes werden die Arbeiten auf der Grundlage der bisherigen
						vertraglichen Vereinbarungen weitergeführt. Der Kunde und THNG können schriftlich vereinbaren,
						dass von einem Änderungsvorschlag betroffene Leistungen bis zur Beendigung der Prüfung oder –
						soweit ein Änderungsangebot unterbreitet wird – bis zum Ablauf der Bindefrist unterbrochen
						werden. Die Leistungszeiträume verlängern sich um die Zahl der Arbeitstage, an denen die
						Arbeiten im Zusammenhang mit dem Änderungsvorschlag oder seiner Prüfung einvernehmlich bzw. nach
						Abstimmung unterbrochen wurde.
					</li>
					<li>
						Wird die angebotene Änderung der Vertragsbedingungen nicht innerhalb der Bindefrist angenommen,
						so werden die Arbeiten auf der Grundlage des Vertrages weitergeführt. THNG kann für die Dauer
						der Unterbrechung die vereinbarte Aufwandsvergütung oder eine angemessene Erhöhung des
						vereinbarten Festpreises verlangen, es sei denn, dass THNG seine Arbeitskraft bzw. die von der
						Unterbrechung betroffenen Arbeitnehmer anderweitig eingesetzt oder einzusetzen böswillig
						unterlassen hat.
					</li>
				</ol>

				<h5>5 Vergütung, Zahlungsbedingungen</h5>
				<ol>
					<li>
						Soweit nichts Gegenteiliges ausdrücklich schriftlich vereinbart ist, gelten die Preise von THNG
						für Kaufverträge ab Werk ausschließlich Verpackung und Versand und zuzüglich Umsatzsteuer in
						jeweils gültiger Höhe. Kosten für Verpackung und Versand werden gesondert in Rechnung gestellt.
					</li>
					<li>
						Dienst- und Werkleistungen erfolgen zu den Preisen und besonderen Bedingungen des jeweiligen
						Vertrages. Die darin genannten Preise sind verbindlich. Soweit keine Festpreise vereinbart sind,
						richtet sich die Höhe des Preises für die jeweilige Lieferung bzw. Leistung nach der jeweils zum
						Zeitpunkt der Auftragsbestätigung gültigen Preisliste.
					</li>
					<li>
						Soweit nicht anders vereinbart, verstehen sich die Preise „Netto“ zuzüglich der jeweils
						gesetzlich geschuldeten Umsatzsteuer von z.Z. 19%.
					</li>
					<li>
						Soweit nicht anders vereinbart, gelten die Preise bzw. Tagessätze zzgl. entstehender Reiseund
						Nebenkosten. Ein Tag umfasst 8 Stunden. Spesen für Übernachtung sowie Fahrtkosten (Mietwagen,
						Kraftstoffe, Bahn, Flug) zahlt der Kunde entsprechend tatsächlichem Aufwand,
						Verpflegungsmehraufwendungen gemäß der gesetzlichen Regelung. Reisezeiten werden mit dem halben
						Stundensatz berechnet. Die Kilometerpauschale für eingesetzte Pkw beträgt 0,40 €/km.
					</li>
					<li>
						Die Rechnungsstellung erfolgt nach Leistungserbringung. THNG ist berechtigt, angemessene
						Abschlagszahlungen zu verlangen.
					</li>
					<li>
						Sofern nicht anders vereinbart, ist der Rechnungsbetrag innerhalb von 14 Tagen nach
						Rechnungsstellung zu zahlen. Verzugszinsen werden in Höhe von 9 % über dem jeweiligen
						Basiszinssatz p.a. berechnet. Die Geltendmachung eines höheren Verzugsschadens bleibt
						vorbehalten.
					</li>
					<li>
						Kommt der Kunde mit Zahlungen in Verzug und leistet er trotz zweifacher Mahnung nicht, so ist
						THNG berechtigt, weitere Leistungen aus demselben rechtlichen Verhältnis, zu denen THNG
						verpflichtet ist, vorläufig einzustellen und sämtliche offenen Beträge aus diesem Verhältnis
						sofort fällig zu stellen. Etwa vereinbarte Termine bzw. Fristen zur Ausführung von noch
						ausstehenden Lieferungen und Leistungen sind in diesem Falle hinfällig, ohne dass es eines
						besonderen Hinweises von THNG hierauf bedarf.
					</li>
				</ol>

				<h5>6 Eigentums- und Rechtevorbehalt</h5>
				<ol>
					<li>
						THNG behält sich sämtliche Rechte an den Lieferungen bzw. Leistungen bis zur vollständigen
						Bezahlung aller Forderungen aus dem jeweiligen Vertrag vor. Dies gilt Stand November 2018 4
						insbesondere für das Eigentum an den gegenständlichen Lieferungen (z.B. Datenträger,
						Benutzerhandbücher, sonstige Dokumentation etc.) als auch für geistige Eigentumsrechte (z.B.
						urheberrechtliche Nutzungsrechte an Softwareprogrammen und Benutzerhandbüchern).
					</li>
					<li>
						Vor vollständiger Bezahlung dürfen Lieferungen und Leistungen von THNG weder an Dritte
						verpfändet noch zur Sicherheit übereignet werden. Solange das Eigentum noch nicht übergegangen
						ist, hat der Kunde THNG unverzüglich schriftlich zu informieren, wenn der gelieferte Gegenstand
						gepfändet oder sonstigen Eingriffen Dritter ausgesetzt ist. Soweit der Dritte nicht in der Lage
						ist, THNG die gerichtlichen und außergerichtlichen Kosten einer Klage gem. § 771 ZPO zu
						erstatten, haftet der Kunde für den THNG entstandenen Ausfall.
					</li>
				</ol>

				<h5>7 Überlassung von Software/Dokumentationsmaterial</h5>
				<ol>
					<li>
						Beinhaltet der Vertragsgegenstand die Überlassung von Software, räumt THNG – sofern nicht
						vertraglich anders vereinbart – dem Kunden das nicht weiter übertragbare und nicht
						ausschließliche Recht ein, die im Auftrag näher spezifizierte Software und, sofern vorhanden,
						das Dokumentationsmaterial auf unbestimmte Zeit zu nutzen. Einsatzbereich, Leistungsfähigkeit
						sowie alle anderen spezifischen Softwareeigenschaften bestimmen sich allein aus der der Software
						beigefügten Dokumentation.
					</li>
					<li>
						Sofern Standardsoftware Gegenstand der vertraglich geschuldeten Leistungen ist, darf von der
						gelieferten Standardsoftware nur in Übereinstimmung mit den Lizenzbedingungen des jeweiligen
						Herstellers Gebrauch gemacht werden. Ein Nichtbefolgen dieser Lizenzbedingungen führt unter
						anderem zum Entzug der Lizenz.
					</li>
					<li>
						Die dem Kunden überlassene Software, insbesondere der Quellcode, verbleibt einschließlich der
						gesamten Dokumentation im Eigentum von THNG. THNG bleibt Inhaber aller Urheber- und
						Nutzungsrechte an der dem Kunden überlassenen Software einschließlich des jeweiligen
						Dokumentationsmaterials, auch wenn der Kunde sie verändert oder mit seiner Software und/oder
						derjenigen eines Dritten verbindet. Bei derartigen Änderungen oder Verbindungen sowie bei der
						Erstellung von Kopien bringt der Kunde einen entsprechenden Urhebervermerk an. Änderungen und
						Erweiterungen des Quellcodes, die auf Wunsch und Rechnung des Kunden durchgeführt werden, gehen
						in das Eigentum von THNG über und können anderen Kunden nach Zustimmung des Kunden zur Verfügung
						gestellt werden. Stand November 2018 5 Die Nutzungsrechte für die Softwareänderungen werden an
						den Kunden abgetreten. Der Kunde nimmt die Abtretung an.
					</li>
				</ol>

				<h5>8 Update und Support</h5>
				<ol>
					<li>
						Wird THNG beauftragt, die Wartung und den Support von Software zu übernehmen, so wird
						vertraglich in einem Leistungsschein (SLA) der Umfang dieser Leistungen vereinbart. Die so
						vereinbarten Leistungen werden in Form einer Wartungs- und Supportpauschale vergütet.
					</li>
					<li>
						Soweit nicht anders vereinbart umfasst die Pauschale folgende Leistungen:
						<ol type="a">
							<li>die Beseitigung von Fehlern im Quellcode</li>
							<li>die Aktualisierung von Software (Updating)</li>
							<li>den Austausch verbesserter Standardsoftware einschließlich Dokumentation</li>
							<li>
								Beratung des Kunden (per Telefon, Telefax und/oder Email) in Fragen, die sich für ihn
								bei der Softwarenutzung ergeben
							</li>
							<li>
								Pflege erstreckt sich auch auf die zu der Software gehörenden Dokumentationen sowie auf
								Dateien oder Datenbankmaterial, die vom Leistungsschein umfasst sind.
							</li>
						</ol>
					</li>
					<li>
						THNGist verpflichtet, vom Kunden gemeldete reproduzierbare Fehler der Software zu untersuchen
						und dem Kunden nach Möglichkeit einen Workaround (Übergangslösung) zur Verfügung zu stellen. Ein
						Fehler liegt insbesondere dann vor, wenn die Software eine in ihrer Leistungsbeschreibung
						angegebene Funktion nicht oder nicht zutreffend erfüllt oder sich in anderer Weise nicht
						funktionsgerecht verhält.
					</li>
					<li>
						Bei wesentlichen Fehlern der Software ist THNG verpflichtet, den Fehler in einem der folgenden
						Updates zu beseitigen. Voraussetzung für die Suche und die Beseitigung von Fehlern ist die
						Erfüllung der dem Kunden obliegenden Mitwirkungspflichten.
					</li>
				</ol>

				<h5>9 Leistungserbringung</h5>
				<ol>
					<li>
						Ort der Leistungserbringung ist soweit im Einzelfall nichts anderes vereinbart, der
						Geschäftssitz von THNG.
					</li>
					<li>
						Die mit der Durchführung der Leistung befassten Mitarbeiter werden von THNG ausgesucht. Der
						Kunde hat keinen Anspruch auf die Leistungserbringung durch bestimmte Mitarbeiter. Bei der
						Auswahl wird THNG die Interessen des Kunden angemessen berücksichtigen. THNG erbringt die
						Leistungen durch Personen, die für die Erbringung Stand November 2018 6 der vereinbarten
						Leistungen qualifiziert sind. Wird eine von THNG zur Vertragserfüllung eingesetzte Person durch
						eine andere ersetzt und ist eine Einarbeitung erforderlich, so geht diese zu Lasten von THNG.
					</li>
					<li>
						THNG bestimmt – nach Maßgabe des Leistungsgegenstandes – die Art und Weise der
						Leistungserbringung.
					</li>
					<li>
						Die Mitarbeiter von THNG treten in kein Arbeitsverhältnis zum Kunden. Der Kunde ist gegenüber
						THNG bzw. den mit der Leistungserbringung befassten Mitarbeitern von THNG mit Ausnahme des im
						Rahmen von § 16 Abs. 2 vereinbarten, nicht weisungsbefugt.
					</li>
					<li>
						Die Einschaltung von Subunternehmen ist nur mit vorheriger, ausdrücklicher Zustimmung des Kunden
						möglich.
					</li>
					<li>
						Sofern THNG das Ergebnis einer Leistung schriftlich darzustellen hat, ist nur die schriftliche
						Darstellung maßgeblich.
					</li>
					<li>
						Nicht vom Vertrag erfasste zusätzliche Leistungen übernimmt THNG auf schriftliche Anforderung
						des Kunden gegen gesonderte Zahlung. Die Berechnung erfolgt auf der Basis der zum Zeitpunkt der
						Durchführung gültigen Stundensätze von THNG.
					</li>
				</ol>

				<h5>10 Mitwirkungspflichten des Kunden bei Dienstleistungs-/Werkverträgen</h5>
				<ol>
					<li>
						Der Kunde benennt THNG einen qualifizierten Ansprechpartner, der während der Durchführung der
						vertraglich vereinbarten Leistung für den Kunden verbindliche Entscheidungen treffen kann.
						Dieser hat für den Austausch notwendiger Informationen zur Verfügung zu stehen und bei den für
						die Vertragserfüllung notwendigen Entscheidungen mitzuwirken. Erforderliche Entscheidungen des
						Kunden sind vom Ansprechpartner unverzüglich herbeizuführen und von den Vertragspartnern im
						unmittelbaren Anschluss gemeinsam schriftlich zu dokumentieren.
					</li>
					<li>
						Der Kunde trägt Sorge dafür, dass THNG die für die Erbringung der Leistung notwendigen
						Unterlagen, Informationen, technischen Daten, Computerprogramme, Akten, Dokumentationen,
						Prüfdaten, Hilfsmittel etc., soweit diese nicht von THNG geschuldet sind, vollständig, richtig,
						rechtzeitig und kostenfrei zur Verfügung stehen. Darüber hinaus sorgt der Kunde für deren
						Aktualisierung. THNG darf, außer Gegenteiliges wird erkannt oder hätte erkannt werden müssen,
						von der Vollständigkeit und Richtigkeit dieser Unterlagen ausgehen.
					</li>
					<li>
						Um einen reibungslosen Ablauf der Ausführung der vereinbarten Leistungen zu gewährleisten, wird
						der Kunde notwendige Entscheidungen innerhalb eines angemessenen Zeitraums (i.d.R. 2 Tage)
						treffen.
					</li>
					<li>
						Der Kunde ist für die Bereitstellung bzw. Schaffung der erforderlichen baulichen, technischen
						und sonstigen Voraussetzungen, die dem Kunden von THNG benannt werden, verantwortlich.
					</li>
					<li>
						Der Kunde ist verpflichtet, Testszenarien zu definieren (z.B. Funktionstests, Spezialfälle
						prüfen). Er ist ferner zur fristgerechten Bereitstellung von Daten und geeigneten
						Geschäftsvorfällen zu Testzwecken verpflichtet sowie zur Durchführung von Tests.
					</li>
					<li>
						Der Kunde wird auftretende Fehler THNG unverzüglich mitteilen und THNG bei der
						Fehleruntersuchung und Fehlerbeseitigung im Rahmen des Zumutbaren unterstützen. Hierzu gehört
						insbesondere, THNG auf dessen Anforderung schriftliche Mängelberichte vorzulegen und sonstige
						Daten und Protokolle bereitzustellen, die zur Analyse des Fehlers geeignet und erforderlich
						sind.
					</li>
					<li>
						Zwecks Vermeidung von Schäden – und soweit vertraglich nicht anders vereinbart – ist der Kunde
						angehalten, dafür Sorge zu tragen, dass sein Datenbestand täglich dem Stand der Technik
						entsprechend gesichert wird.
					</li>
					<li>
						Der Kunde ist verpflichtet, THNG soweit erforderlich zu unterstützen und in seiner
						Betriebssphäre alle zur ordnungsgemäßen Durchführung der Beauftragung erforderlichen
						Voraussetzungen zu schaffen. Darüber hinaus stellt der Kunde auf Wunsch von THNG ausreichende
						Arbeitsplätze und Arbeitsmittel zur Verfügung.
					</li>
					<li>
						Soweit nichts anderes vereinbart ist, wird der Kunde alle von THNG übergebenen Unterlagen,
						Informationen usw. bei sich so verwahren, dass diese bei Beschädigungen oder Verlust
						rekonstruiert werden können.
					</li>
				</ol>

				<h5>11 Mängelrügen / Mängelhaftung</h5>
				<ol>
					<li>
						Der Kunde ist verpflichtet, Lieferungen und Leistungen unverzüglich auf Vollständigkeit und
						offensichtliche Mängel, insbesondere auch auf offensichtliche Fehlmengen oder Beschädigungen, zu
						untersuchen und diese unverzüglich, spätestens innerhalb von zwei Wochen nach Erhalt der
						Lieferung bzw. Leistung, THNG gegenüber schriftlich zu rügen. Bei nicht offensichtlichen
						(verborgenen) Mängeln, ist der Kunde verpflichtet, diese nach ihrer Entdeckung, spätestens
						innerhalb der Verjährungsfrist schriftlich zu rügen. Unterlässt der Kunde die vorstehend
						bestimmten Rügen, ist die Haftung für den nicht gerügten Mangel ausgeschlossen.
					</li>
					<li>
						Den Kunden trifft die Beweislast für die Einhaltung und Rechtzeitigkeit der Rügeverpflichtung
						sowie für das Vorliegen und den Zeitpunkt der Feststellung eines Mangels.
					</li>
					<li>
						THNG haftet dafür, dass die vertragsgegenständliche Software während der Vertragslaufzeit die im
						Vertrag bzw. Leistungsschein spezifizierten Funktionen aufweist.
					</li>
				</ol>

				<h5>12 Leistungsstörungen bei Kaufverträgen</h5>
				<ol>
					<li>
						Gewährleistungsrechte des Kunden setzen voraus, dass dieser seinen nach § 377 HGB geschuldeten
						Untersuchungs- und Rügeobliegenheiten ordnungsgemäß nachgekommen ist.
					</li>
					<li>
						Sollte trotz aufgewendeter Sorgfalt die gelieferte Ware einen Mangel aufweisen, der bereits zum
						Zeitpunkt des Gefahrübergangs vorlag, so wird THNG die Ware, vorbehaltlich fristgerechter
						Mängelrüge nach der Wahl von THNG nachbessern oder Ersatzware liefern. Es ist THNG stets
						Gelegenheit zur Nacherfüllung innerhalb angemessener Frist zu geben. Rückgriffansprüche bleiben
						von vorstehender Regelung ohne Einschränkung unberührt. Schlägt die Nacherfüllung fehl, kann der
						Kunde – unbeschadet etwaiger Schadenersatzansprüche – vom Vertrag zurücktreten oder die
						Vergütung mindern.
					</li>
					<li>
						Mängelansprüche bestehen nicht bei nur unerheblicher Abweichung von der vereinbarten
						Beschaffenheit, bei nur unerheblicher Beeinträchtigung der Brauchbarkeit, bei natürlicher
						Abnutzung oder Verschleiß wie bei Schäden, die nach dem Gefahrübergang infolge fehlerhafter oder
						nachlässiger Behandlung, übermäßiger Beanspruchung oder ungeeigneter Betriebsmittel entstehen,
						die nach dem Vertrag nicht vorausgesetzt sind. Werden vom Kunden oder Dritten unsachgemäß
						Instandsetzungsarbeiten oder Änderungen vorgenommen, so bestehen für diese und die daraus
						entstehenden Folgen ebenfalls keine Mängelansprüche
					</li>
					<li>
						Ansprüche des Kunden wegen der zum Zweck der Nacherfüllung erforderlichen Aufwendungen,
						insbesondere Transport-, Wege-, Arbeits- und Materialkosten, sind ausgeschlossen, soweit die
						Aufwendungen sich erhöhen, weil die von THNG gelieferte Ware nachträglich an einen anderen Ort
						als die Niederlassung des Kunden verbracht worden ist, es sei denn, die Verbringung entspricht
						ihrem bestimmungsgemäßen Gebrauch.
					</li>
				</ol>

				<h5>13 Leistungsstörungen bei Werkverträgen</h5>
				<ol>
					<li>
						THNG ist berechtigt, bei reproduzierbaren Fehlern der Software, dem Kunden zunächst einen
						Workaround (Übergangslösung) zur Verfügung zu stellen. THNG ist verpflichtet, den Fehler sodann
						mit dem nächsten Update zu beseitigen. Bei Fehlern, die die Nutzung der Software auch mit einem
						Workaround erheblich beeinträchtigen, ist Stand November 2018 9 THNG verpflichtet, den Fehler
						unverzüglich zu beseitigen. Kommt THNG dieser Pflicht zur Mängelbeseitigung innerhalb einer
						angemessenen Frist nicht nach, so kann der Kunde den Mangel entweder selbst beseitigen und
						Ersatz der erforderlichen Aufwendungen, Herabsetzung der Vergütung oder Schadenersatz statt der
						Leistung verlangen oder vom Vertrag zurücktreten.
					</li>
					<li>
						In allen anderen von Ziffer 1 nicht erfassten Fällen ist THNG verpflichtet, wenn die Leistung
						nicht vertragsgemäß erbracht wurde und dies von THNG zu vertreten ist (Leistungsstörung), die
						Leistung ganz oder in Teilen ohne Mehrkosten für den Kunden innerhalb angemessener Frist
						vertragsgemäß zu erbringen, es sei denn, dies ist nur mit unverhältnismäßigem Aufwand möglich.
						Diese Pflicht von THNG besteht – soweit nichts anderes vereinbart ist – nur, wenn der Kunde die
						Leistungsstörung schriftlich und unverzüglich, spätestens aber bis zum Ablauf von zwei Wochen
						nach Kenntniserlangung der nicht vertragsgemäßen Leistungserbringung rügt. Der Kunde hat dazu
						die Leistungserbringung durch THNG angemessen zu beobachten.
					</li>
					<li>
						Hat THNG eine nicht vertragsgemäße Leistung zu vertreten und gelingt THNG die Erbringung der
						vertragsgemäßen Leistung auch innerhalb der vom Kunden gesetzten Nachfrist aus von THNG zu
						vertretenden Gründen nicht, so ist der Kunde berechtigt, entweder den Mangel selbst zu
						beseitigen und von THNG Ersatz der erforderlichen Aufwendungen, Herabsetzung der Vergütung oder
						Schadenersatz statt der Leistung zu verlangen oder vom Vertrag zurückzutreten.
					</li>
				</ol>

				<h5>14 Haftung</h5>
				<ol>
					<li>
						THNG schließt die Haftung für leicht fahrlässige Pflichtverletzungen aus, sofern nicht Schäden
						aus der Verletzung des Lebens, des Körpers, der Gesundheit, der Übernahme von Garantien für die
						Beschaffenheit einer Sache oder Ansprüche nach dem Produkthaftungsgesetz berührt sind.
					</li>
					<li>
						THNG haftet begrenzt auf die Höhe des vertragstypisch vorhersehbaren Schadens für Schäden aus
						der Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages
						überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf. Gleiches
						gilt für Pflichtverletzungen von durch THNG beauftragte Erfüllungsgehilfen.
					</li>
				</ol>

				<h5>15 Vertragsdauer / Kündigung / Projektabschluss</h5>
				<ol>
					<li>
						Soweit vertraglich nicht anders vereinbart, beginnt der Vertrag bei monatlich wiederkehrenden
						Leistungen (z.B. Wartungsverträgen) mit dem Tag der Arbeitsaufnahme und hat eine Mindestlaufzeit
						von 1 Jahr. Der Vertrag verlängert sich automatisch um ein Jahr, sofern er nicht mit einer Frist
						von 1 Monat vor Ablauf der Mindestlaufzeit bzw. Verlängerung gekündigt wird.
					</li>
					<li>
						Einmalige Leistungen beginnen mit Vertragsschluss. Sofern keine gesonderte Abnahme vereinbart
						ist, gilt der Vertrag als beendet und das Projekt als abgeschlossen, wenn der Kunde die von THNG
						erbrachten Leistungen nutzt. Sofern der Kunde einmalige Leistungen ganz oder teilweise
						storniert, ist THNG berechtigt, neben den erbrachten Leistungen und aufgelaufenen Kosten eine
						Stornogebühr von 20% des noch nicht abgerechneten Auftragswertes des Gesamtprojektes
						abzurechnen.
					</li>
					<li>
						Die Kündigung aus wichtigem Grund bleibt von vorstehenden Ausführungen unberührt. Ein wichtiger
						Grund liegt insbesondere vor, wenn:
						<ol type="a">
							<li>
								der Kunde mit fälligen Zahlungen trotz Mahnung ganz oder auch nur teilweise in Verzug
								ist.
							</li>
							<li>
								auch nach schriftlicher Abmahnung gegen eine sonstige wesentliche Bestimmung des
								Vertrages oder dieser AGB verstoßen wird.
							</li>
							<li>
								begründete Bedenken hinsichtlich der Zahlungsfähigkeit des Kunden bestehen und binnen
								einer angemessenen Frist auf Anforderung weder Vorauszahlung leistet, noch eine
								angemessene Sicherheit erbracht wird.
							</li>
							<li>
								die Ausführung eines Auftrages durch Gründe, die der Kunde zu vertreten hat, erheblich
								verzögert oder unmöglich wird.
							</li>
						</ol>
					</li>
				</ol>

				<h5>17 Datenschutz und Verschwiegenheitsverpflichtung</h5>
				<ol>
					<li>
						Sowohl THNG als auch der Kunde sind verpflichtet, über Geschäfts- und Betriebsgeheimnisse sowie
						über sonstige als vertraulich bezeichnete Informationen, die im Zusammenhang mit dem
						Vertragsverhältnis bzw. der daraus resultierenden Vertragsbeziehung bekannt werden,
						Stillschweigen zu wahren. Die Weitergabe solcher Informationen an Personen, die nicht an dem
						Abschluss, der Durchführung oder der Abwicklung des Vertragsverhältnisses beteiligt sind, darf
						nur mit schriftlicher Einwilligung des Vertragspartners erfolgen. Diese Verpflichtungen gelten
						auch nach Beendigung des Vertragsverhältnisses weiter. Die Vertragspartner werden diese
						Verpflichtungen auch ihren Mitarbeitern und eventuell eingesetzten Dritten auferlegen.
					</li>
					<li>
						Soweit THNG auf personenbezogene Daten zugreifen kann, die auf Systemen des Kunden gespeichert
						sind, wird THNG ausschließlich als Auftragsdatenverarbeiter tätig (§ 11 Abs. 5 BDSG) und diese
						Daten nur zur Vertragsdurchführung verarbeiten und nutzen. THNG wird die gesetzlichen
						Erfordernisse der Auftragsdatenverarbeitung und Weisungen des Kunden (z.B. zur Einhaltung von
						Lösch- und Sperrpflichten) für den Umfang mit diesen Daten beachten. Der Kunde trägt etwaige
						nachteilige Folgen solcher Weisungen für die Vertragsdurchführung. Details für den Umgang mit
						personenbezogenen Daten werden die Vertragspartner soweit gemäß § 11 Abs. 2 BDSG oder sonstiger
						Rechtsnormen notwendig, vor der Zugriffsmöglichkeit durch THNG schriftlich vereinbaren. Die
						Vertragspartner werden diese Verpflichtungen auch ihren Mitarbeitern und eventuell eingesetzten
						Dritten auferlegen.
					</li>
					<li>
						THNG sowie dem Kunden ist bekannt, dass eine elektronische und unverschlüsselte Kommunikation
						(z.B. per E-Mail) mit Sicherheitsrisiken behaftet ist. Bei dieser Art der Kommunikation werden
						weder THNG, noch der Kunde daher Ansprüche geltend machen, die durch das Fehlen einer
						Verschlüsselung begründet sind, außer soweit zuvor eine Verschlüsselung vereinbart worden ist.
					</li>
				</ol>

				<h5>18 Schriftformklausel / Teilunwirksamkeit</h5>
				<ol>
					<li>
						Änderungen und Ergänzungen sämtlicher zwischen den Parteien geschlossener Verträge sollen nur
						schriftlich vereinbart werden. Mündliche Absprachen gelten nur, wenn sie binnen sieben Tagen
						schriftlich durch THNG bestätigt werden. Ein Fax bzw. eine E-Mail genügt dem
						Schriftformerfordernis.
					</li>
					<li>
						Sollten einzelne Bestimmungen dieser Geschäftsbedingungen unwirksam sein oder werden oder eine
						Lücke enthalten, so bleiben die übrigen Bestimmungen hiervon unberührt. Die Parteien
						verpflichten sich, anstelle der unwirksamen Regelung eine solche gesetzlich zulässige Regelung
						zu treffen, die dem wirtschaftlichen Zweck der unwirksamen Regelung am nächsten kommt, bzw.
						diese Lücke ausfüllt.
					</li>
				</ol>

				<h5>19 Anwendbares Recht / Erfüllungsort und Gerichtsstand</h5>
				<ol>
					<li>
						Sämtliche Vertragsverhältnisse der Parteien unterliegen ausschließlich dem Recht der
						Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (CISG).
					</li>
					<li>
						Erfüllungsort für alle Verpflichtungen aus den Vertragsverhältnissen der Parteien ist der
						Geschäftssitz von THNG.
					</li>
					<li>
						Gerichtsstand für alle Streitigkeiten aus den Vertragsverhältnissen der Parteien sowie für
						Streitigkeiten in Bezug auf das Entstehen und die Wirksamkeit dieser Vertragsverhältnisse ist
						der Geschäftssitz von THNG.
					</li>
				</ol>
			</div>
		</div>
	</>
);

export default AGB;
